<template>
  <div>
    <Datatable
      ref="terminalDatatable"
      itemKey="uuid"
      :api-endpoint="ENDPOINTS.DATATABLES.KIOSK.TERMINALS"
      :datatable-headers="datatableHeaders"
      no-excel-export
      show-select-checkbox
      show-edit-buttons
      show-delete-buttons
      @editEntry="editEntry"
      @deleteEntry="deleteEntry"
      @deleteEntries="deleteEntries"
    />

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        id="onScreenKeyboard"
        class="internalWidthExpanded"
        :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet"
        v-if="touchKeyboard.visible"
        :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard"
        :accept="hideTouchKeyboard"
        :input="touchKeyboard.input"
      />
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";

//components
import Datatable from "../../datatable/Datatable";

export default {
  name: "TerminalsComponent",

  components: {
    Datatable,
  },

  mixins: [mixin],

  data: () => {
    return {
      ENDPOINTS,
    };
  },

  computed: {
    // 'uuid', 'alias','location','cashierId'
    datatableHeaders: function () {
      return [
        {
          text: "UUID",
          align: "left",
          value: "uuid",
          hide: true,
        },
        {
          text: this.$t("generic.lang_alias"),
          value: "alias",
        },
        {
          text: this.$t("generic.lang_location"),
          value: "location",
        },
        {
          text: this.$t("generic.lang_cashierID"),
          value: "cashierId",
        },
      ];
    },
  },

  methods: {
    editEntry(entry) {
      this.$router.push({
        name: "kiosk.terminals.edit.terminal",
        params: { id: entry.uuid },
      });
    },
    deleteEntry(entry) {
      let idsToDelete = [];
      idsToDelete.push(entry.uuid);

      this.deleteData(idsToDelete);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    deleteData(idsToDelete) {
      this.$swal({
        title: this.$t("generic.lang_deleteTerminal"),
        text: this.$t("generic.lang_deleteConfirmationMSG"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios
            .post(ENDPOINTS.KIOSK.TERMINALS.DELETE, {
              Ids: idsToDelete,
            })
            .then((res) => {
              if (res.data.status == "SUCCESS") {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_success"),
                  color: "success",
                });

                this.$refs.terminalDatatable.getDataFromApi();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
            })
            .catch(() => {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
  },
};
</script>
