<template>
  <div>
    <page-title
      :heading="$t('generic.lang_terminals')"
      :subheading="$t('generic.lang_terminals')"
      :icon="icon"
      show-add-button
      url-add="/kiosk/terminals/create-terminal"
      permissionAdd
    ></page-title>

    <div class="app-main__inner">
      <terminals-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import TerminalsComponent from "@/components/kiosk/kioskManagement/TerminalsComponent";

export default {
  components: {
    TerminalsComponent,
    PageTitle,
  },

  data() {
    return {
      icon: "pe-7s-search icon-gradient bg-tempting-azure",
    };
  },
};
</script>
